import React, { Component } from "react";
import { Redirect } from "react-router-dom";
 import { MDBContainer, MDBRow, MDBCol, MDBTypography } from "mdbreact";
import Logo from '../images/logo.png';
import logoSvg from '../images/svg/logo.svg';
import FB from '../images/svg/faccebook.svg';
import TW from '../images/svg/twitter.svg';
import PT from '../images/svg/Instagram.svg';
import WK from '../images/svg/linkdin.svg';
export default class Footer extends Component {
  componentDidMount() {
 
  }

  render() {
    return (
      <div className="container " style={{backgroundColor:'#F8FDFD'}}>
      <MDBContainer className="pt-5 mt-5"></MDBContainer>
       <footer class="textToCenter textToleft text-lg-start" style={{backgroundColor:'#F8FDFD'}}>
   <div class="p-4">
     <div class="row">
       <div class="col-lg-6 col-md-12 mb-4 mb-md-0">
        <div className="text-left leftPadBtn">
        <div className="logoFooter">
        <a className="hide">
              <img className="img-fluid   btnLeftFooter" style={{width:"280px",backgroundSize: 'cover' }} src={logoSvg}   alt="mdb logo"/>
              </a>
              <a  className="hide1 ">
                 <img className="img-fluid btnleftMargin  mx-auto" style={{width:"280px",backgroundSize: 'cover' }} src={logoSvg}   alt="mdb logo"/>
              </a>
        {/* <img className="img-fluid" style={{width:"200px"}} src={logoSvg} /> */}
        </div> 
        {/* <p className="text-left pl-sm-2">
        Transform Patient Monitoring Experience. 
        <br/>
        Save Lives.        </p> */}
        </div>
      </div>
 
       <div class="col-lg-6 alignToleft logoFooter col-md-12 mb-4 mb-md-0 textToleft  text-sm-right">
       <div className=" ">
     

       <a  href="https://m.facebook.com/Remocares/">   
       <img className="img-fluid pr-4" style={{width:40,height:20}} src={FB} />
              </a>
               <a  href="https://twitter.com/remocares?s=11">   
              <img className="img-fluid pr-4" style={{width:50,height:30}} src={TW} />
              </a>
                <a  href="https://www.instagram.com/remocares/?igshid=1gd5ri5ibh0qe">   
               <img className="img-fluid pr-4" style={{width:45,height:30}} src={ PT} />
              </a>             
               <a  href="https://www.linkedin.com/company/remocare">   
              <img className="img-fluid pr-4" style={{width:45,height:30}} src={WK} />
              </a>  
        </div>
        <p className="mt-2">
        Send us a question  <a style={{color:"#84DCE3"}}>hello@remo.care</a>
        </p>
      </div>
     </div>
   </div>
   <hr className="pb-0 mb-0"/>
   <div className="text-center mb-2 pb-2" style={{backgroundColor: "#F8FDFD"}}>
     <a className="text-dark pb-2 mb-2 pt-0">© 2021 Remo.Care | ALL RIGHTS RESERVED<a href="https://shubhchintak.co/" target="_blank">.</a></a>
  </div>
  <div className="mt-2 pt-2"> </div>
 </footer>
      </div>
    );
  }
}
