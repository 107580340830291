import * as React from "react";
import { render } from "react-dom";
import { ProgressBar,Step } from "react-step-progress-bar";
import { MDBContainer, MDBRow, MDBCol, MDBTypography } from "mdbreact";
import LineTime from '../../images/svg/LineTime.svg'
import "react-step-progress-bar/styles.css";
import AI from '../../images/AI.png'
import Cloud from '../../images/cloud.png'
import Doctor from '../../images/doctor.png'
import Man from '../../images/man.png'
import Ipad1 from '../../images/dash1.png'
import './timeline.css'
import $ from 'jquery';
import TimeLineSmall from "./timeline";
import TimeLineSmall2 from "./timeline2";

    class Slider1 extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
          scroll: 0
        };
      }
    
  componentDidMount() {
     window.addEventListener("scroll", this.progressBar);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.progressBar);
  }

  progressBar = () => {
    $(document).scroll(function (e) {
        var scrollAmount = $(window).scrollTop();
        var documentHeight = $(document).height();
        var windowHeight = $(window).height();
        var scrollPercent = (scrollAmount / (documentHeight - windowHeight)) * 100;
         $(".scrollBar2").css("height", scrollPercent + "%");
       });
      

  };

  render() {
  return (
    <div className="mt-3 pt-3">
    <div className="  orientationcss">
    <TimeLineSmall className="ml-5 pl-5"/>
    </div>
     <div className='pl-3 setWidth d-flex flex-row'>
   
    <div md="2" style={{width:'40%'}}>
       <img className="img-fluid " style={{width:''}} src={Man} />
      </div>
      <div md="2" style={{width:'20%'}}>
       </div>
    <div className="" sm="1" style={{width:'40%'}}>
      <p className="textBold pb-0">Patient</p>
      <p  className="">Patient wears the remo.cardia FDA cleared multi-vitals monitoring patch.</p>
       </div>
       </div>
        {/*  */}
       <div className='mt-5 pt-5 pl-3 setWidth d-flex flex-row'>
       <div className="text-right" sm="1" style={{width:'40%'}}>
      <p className="textBold pb-0">App</p>
      <p  className="">Remo.cardia patient app collects vital data from patch and uploads to secure cloud.</p>
       </div>
       <div md="2" style={{width:'20%'}}>
          </div>
   
        <div md="2" style={{width:'30%'}}>
          <img className="img-fluid " style={{width:''}} src={Ipad1} />
          </div>
         
    
       </div>
       {/*  */}
       <div className='mt-5 pt-5 pl-3 setWidth d-flex flex-row'>
    <div md="2" style={{width:'40%'}}>
       <img className="img-fluid " style={{width:''}} src={Cloud} />
      </div>
      <div md="2" style={{width:'20%'}}>
       </div>
    <div className="" sm="1" style={{width:'40%'}}>
      <p className="textBold pb-0">Cloud</p>
      <p  className="">Secure cloud syncs data for analysis and data storage.</p>
       </div>
    
       </div>
       {/*  */}
       <div className='mt-5 pt-5 pl-3 setWidth d-flex flex-row'>
       <div className="text-right" sm="1" style={{width:'40%'}}>
      <p className="textBold pb-0">AI</p>
      <p  className="">The Cardiac AI algoritm perfroms analysis of ECG & vitals.</p>
       </div>
       <div md="2" style={{width:'20%'}}>
          </div>
   
        <div md="2" style={{width:'40%'}}>
          <img className="img-fluid " style={{width:''}} src={AI} />
          </div>
         
    
       </div>
       {/*  */}
       <div className='mt-5 pt-5 pl-3 setWidth d-flex flex-row'>
    <div md="2" style={{width:'40%'}}>
       <img className="img-fluid " style={{width:''}} src={Doctor} />
      </div>
      <div md="2" style={{width:'20%'}}>
       </div>
    <div className="" sm="1" style={{width:'40%'}}>
      <p className="textBold pb-0">Doctor</p>
      <p  className="">Doctor monitors multiple patients. Gets alerts in doctor app in case of an event.</p>
       </div>
      </div>
       </div>
         );
      }
    }
    

export default Slider1;

{/* <MDBCol className="pt-5 mt-5" md="3">
<p className="textBold pb-0">Patient</p>
<p  className="">Elementum vel nulla eu sed dictum urna elit. Elementum vel nulla eu sed dictum urna elit.</p>
 </MDBCol>
<div md="2 ml-5 pl-5">
  <img className="img-fluid " style={{width:'auto'}} src={AI} />

</div>
<MDBCol className="pt-5  mt-5" md="3">
<p className="textBold text-center pb-0">Patient</p>
<p>Elementum vel nulla eu sed dictum urna elit. Elementum vel nulla eu sed dictum urna elit.</p>

</MDBCol> */}