import React, { Component } from "react";
import {Helmet} from "react-helmet";
import { MDBContainer, MDBRow, MDBCol, MDBTypography,MDBNavLink } from "mdbreact";
import Landing from '../images/about.png';
import Account from '../images/svg/account.svg';
import Security from '../images/svg/security.svg';
import Vector from '../images/svg/vector.svg';
import Vision from '../images/svg/vision.svg';
import Mission from '../images/svg/mission.svg';
import Revision from '../images/svg/receive.svg';
import Cards from './aboutSection/cards';
import Cardss from './aboutSection/cards1'

export default class About extends Component {
  constructor(props) {
    super(props);

    this.state = {
      content: ""
    };
  }

  componentDidUpdate() {
    window.scrollTo(0,0);
  }

  render() {
    return (
      <>
      <Helmet>
      <title>About us | Remo.care</title>
      <link rel="canonical" href="https://remo.care/about/" />
      <meta property="og:locale" content="en_US" />
      <meta property="og:type" content="article" />
      <meta property="og:title" content="About us | Remo.care" />
      <meta property="og:description" content="ABOUT US​ Remo Care Solutions is a team of professionals from healthcare, digital engineering, biomedical and business backgrounds who have come together to transform health and condition management experience. We believe that the future of healthcare technology can be improved using simple digital solutions which will be predictive and proactive. Vision&nbsp; To improve quality of [&hellip;]" />
      <meta property="og:url" content="https://remo.care/about" />
      <meta property="og:site_name" content="Remo.care" />
          </Helmet>

      <div className="mt-5 pt-3">

      </div>

      <MDBRow className="mPad" fluid>
       <MDBCol sm="1"></MDBCol>
       <MDBCol className="hide"  md="6" sm="6"> 
            <img className="img-fluid pt-sm-5 mt-sm-5" style={{width:'95%'}} src={Landing} />
            </MDBCol>
       <MDBCol className="justify-content-between" sm="5">
            <div  className="pt-sm-5  heading d-flex align-items-start flex-column"> 
            <MDBTypography tag='h1' className="text-left mpadper30 mpadper55 mainTittle pt-5 headFont leftPad"  style={{
          // display: "flex",
          // fontSize:'52px',
          lineHeight:1,
          justifyContent: "left",
          alignItems: "left",
        }} >About Us</MDBTypography>
 
          <p className="textFont leftPad" >
          Remo Care Solutions is a team of professionals from healthcare, digital engineering, biomedical and business backgrounds who have come together to transform health and condition management experience. We believe that the future of healthcare technology can be improved using simple digital solutions which will be predictive and proactive.
          </p>
        <div className="text-left leftPadding10 ">
        <MDBNavLink className="buttonLeft55 " to={"/contact"}>
        <button type="button" class="btn text-white btn-sm homebtn" style={{fontSize:'18px' }}> 
              Contact Us
        <i style={{fontSize:'20px'}} className="pl-5 fas fa-angle-right"></i>
        </button>
        </MDBNavLink>
        </div>
        </div>
            </MDBCol>
            <MDBCol className="hide1"  md="6" sm="6"> 
            <img className="img-fluid pt-sm-5  leftPad mt-sm-5" style={{width:'100%'}} src={Landing} />
            </MDBCol>
       </MDBRow>


       
       <div className="mt-sm-3 pt-sm-3">

</div>
       <div  style={{width:'95%'}} className="hide">
       <MDBContainer>
        <Cardss/>
        </MDBContainer>
        </div>
        <div className="hide1 leftPad5Per">
          <Cards />
        </div>
     
      </>
    );
  }
}



