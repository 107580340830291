import React, { Component } from "react";
import { MDBContainer, MDBRow, MDBCol, MDBTypography } from "mdbreact";
import benefits1 from '../../images/b1.png';
import benefits2 from '../../images/b2.png';
import benefits3 from '../../images/b3.png';

import { Card,  } from 'antd';
 import Slider from "react-slick";
import { CheckCircleOutlined, CaretLeftOutlined,CaretRightOutlined } from '@ant-design/icons';
import { List, Avatar,message } from 'antd';
 
 import "slick-carousel/slick/slick.css"; 
 import '../../styles.css' 
 
 
function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: " "}}
      onClick={onClick}
    />
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: " "}}
      onClick={onClick}
    />
  );
}

export default class Cards extends Component {
  constructor(props) {
    super(props);
    this.next = this.next.bind(this);
    this.previous = this.previous.bind(this);
    this.state = {
      content: ""
    };
  }
  next() {
    this.slider.slickNext();
  }
  previous() {
    this.slider.slickPrev();
  }
  componentDidMount() {
   
  }

  
  render() {
    var settings = {
      dots: true,
      infinite: true,
      arrows:false,
      speed: 500,
      slidesToShow: 1,      
      slidesToScroll: 1,
      centerMode: true,
      className: "center",
      centerPadding: "380px",
    
       responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            infinite: true,
            dots: true
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            initialSlide: 1
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ]
    };
    return (
      <>
      <MDBRow className="text-right pl-0 ml-0 pr-0 mr-0">
      <MDBCol mb="8"></MDBCol>
      <MDBCol mb="2"></MDBCol>
      <MDBCol mb="2">
      <div className="text-right mb-3  d-flex flex-row">
          <div className="" style={{paddingLeft:'75%'}}> 
          <div className="text-right">
          <a onClick={this.previous} className="textBold  " style={{fontSize:'25px',color:"#404040"}} > <CaretLeftOutlined/></a>
          </div>
          </div>   
           <div className="pl-5">
          <div className="text-right">
          <a  onClick={this.next} className="textBold  " style={{fontSize:'25px', color:"#404040"}} ><CaretRightOutlined/></a>
          </div>
          </div>     
                   
        </div>
      </MDBCol>     
      </MDBRow>

         
      <Slider ref={c => (this.slider = c)} style={{width:'100%'}} {...settings}>
          <div className="textFont ">
          <Card style={{ width: 'auto',border:0 }}
            className="pl-5 pr-5"  cover={<img alt="example" src={benefits1}/>}>
            <div className="marginCardsBenefits2 text-center">
            <Card
            className="faq text-left"
            style={{ width: 'auto' }}>
             <h3 className="text-center textBold">Patient</h3>
             <div className="benefitstext" style={{paddingLeft:'10%'}}>
           <div className="d-flex pb-2 text-left">
           <CheckCircleOutlined className="pr-2" style={{fontSize:25,color:'#12A84B'}}/>
           <p className="pb-0 mb-0">Wireless, comfortable, and lightweight.</p>
           </div>
           <div className="d-flex pb-2 text-left">
           <CheckCircleOutlined className="pr-2" style={{fontSize:25,color:'#12A84B'}}/>
           <p className="pb-0 mb-0">Higher patient mobility​.</p>
           </div>
           <div className="d-flex pb-2 text-left">
           <CheckCircleOutlined className="pr-2" style={{fontSize:25,color:'#12A84B'}}/>
           <p className="pb-0 mb-0">Early alerts on cardiac eventualities.</p>
           </div>
           <div className="d-flex pb-2 text-left">
           <CheckCircleOutlined className="pr-2" style={{fontSize:25,color:'#12A84B'}}/>
           <p className="pb-0 mb-0">Doctor can view patient vitals from anywhere, anytime.</p>
           </div>
           <div className="d-flex pb-2 text-left">
           <CheckCircleOutlined className="pr-2" style={{fontSize:25,color:'#12A84B'}}/>
           <p className="pb-0 mb-0">COVID-19 compliant, reduced contacts.</p>    
           </div>
             </div>
             </Card>         
            </div>
             </Card>
          </div>
          <div className="textFont">
          <Card style={{ width: 'auto',border:0 }}
             className="pl-5 pr-5" cover={<img alt="example" src={benefits2}/>}>
            <div className="marginCardsBenefits text-center">
            <Card
            className="faq text-center"
            style={{ width: 'auto' }}>
             <h3 className="text-center textBold">Doctors</h3>
             <div className="benefitstext" style={{paddingLeft:'10%'}}>
             <div className="d-flex pb-2 text-left">
           <CheckCircleOutlined className="pr-2" style={{fontSize:25,color:'#12A84B'}}/>
           <p className="pb-0 mb-0">Remote and real-time monitoring of patients.</p>
           </div>
           <div className="d-flex pb-2 text-left">
           <CheckCircleOutlined className="pr-2" style={{fontSize:25,color:'#12A84B'}}/>
           <p className="pb-0 mb-0">Early event alerts during reversible stages.</p>
           </div>
           <div className="d-flex pb-2 text-left">
           <CheckCircleOutlined className="pr-2" style={{fontSize:25,color:'#12A84B'}}/>
           <p className="pb-0 mb-0"> Track patient symptoms.</p>
           </div>
           <div className="d-flex pb-2 text-left">
           <CheckCircleOutlined className="pr-2" style={{fontSize:25,color:'#12A84B'}}/>
           <p className="pb-0 mb-0">Build effective treatment plan.</p>
           </div>
           <div className="d-flex pb-2 text-left">
           <CheckCircleOutlined className="pr-2" style={{fontSize:25,color:'#12A84B'}}/>
           <p className="pb-0 mb-0">Watch patient health trends.</p>    
           </div>
             </div>
             </Card>         
            </div>
             </Card>
          </div>
          <div className="textFont ">
          <Card style={{ width: 'auto',border:0 }}
             className="pl-5 pr-5" cover={<img alt="example" src={benefits3}/>}>
            <div className="marginCardsBenefits2 text-center">
            <Card
            className="faq text-center"
            style={{ width: 'auto' }}>
             <h3 className="text-center textBold">Hospital</h3>
             <div className="benefitstext" style={{paddingLeft:'10%'}}>
             <div className="d-flex pb-2 text-left">
           <CheckCircleOutlined className="pr-1" style={{fontSize:25,color:'#12A84B'}}/>
           <p className="pb-0 mb-0 text-left">Remote patient monitoring for ICU, Emergency, SDU, Wards, VIP Care and Homecare.</p>
           </div>
           <div className="d-flex pb-2 text-left">
           <CheckCircleOutlined className="pr-2" style={{fontSize:25,color:'#12A84B'}}/>
           <p className="pb-0 mb-0">Faster turnaround of beds.</p>
           </div>
           <div className="d-flex pb-2 text-left">
           <CheckCircleOutlined className="pr-2" style={{fontSize:25,color:'#12A84B'}}/>
           <p className="pb-0 mb-0">Improved doctor to patient ratio.</p>
           </div>
           <div className="d-flex pb-2 text-left">
           <CheckCircleOutlined className="pr-2" style={{fontSize:25,color:'#12A84B'}}/>
           <p className="pb-0 mb-0">Higher patient serviceability and retention.</p>
           </div>
           <div className="d-flex pb-2 text-left">
           <CheckCircleOutlined className="pr-2" style={{fontSize:25,color:'#12A84B'}}/>
           <p className="pb-0 mb-0">Faster return on investment.</p>    
           </div>
             </div>
             <div className="benefitstext">
             </div>   
             </Card>         
            </div>
             </Card>
          </div>  
        </Slider>
      
      </>
    );
  }
}