import React, { Component } from "react";
import { MDBContainer, MDBRow, MDBCol, MDBTypography } from "mdbreact";
import Landing from '../../images/svg/about-us.svg';
import Account from '../../images/svg/account.svg';
import Security from '../../images/svg/security.svg';
import Vector from '../../images/svg/vector.svg';
import Vision from '../../images/svg/vision.svg';
import Mission from '../../images/svg/mission.svg';
import Revision from '../../images/svg/receive.svg';

 
export default class Cards extends Component {
  constructor(props) {
    super(props);

    this.state = {
      content: ""
    };
  }

  componentDidMount() {
   
  }

  render() {
    return (
      <>
      <div className="container mt-5 pt-5" >
      <div className="text-left">
      <div className="card1"  style={{width:'100%'}}>
      <div className="card-body text-left">
         <div className="ml-5">
         <img className="img-fluid mt-2 ml-1 pl-4" style={{width:"55%"}} src={Vision} />
            </div>
      <div className="text-left">
        <h5 className="card-title mt-3">Vision</h5>
        <p className="card-text mt-3">
        Enable healthcare providers to deliver quality care to patients remotely, anywhere, anytime.          
                </p>
        </div>
        <div class="mt-2 pt-5 learn">
          {/* <div className="text-left">
          <a href="/solution" style={{color:"#4A99D3"}} >Learn More <i class="pl-3 fas fa-arrow-right"></i></a>
          </div> */}
          </div>
      </div>

      </div>
      </div>
      <div>
      </div>
      </div>
      <div className="container mt-3" >
      <div className="text-left">
      <div className="card1"  style={{width:'100%'}}>
      <div className="card-body text-left">
      <div className="ml-5">
      <img className="img-fluid mt-5 ml-2 pl-5" style={{width:"55%"}} src={Mission} />
            </div>
      <div className="text-left">
        <h5 className="card-title mt-5">Mission</h5>
        <p className="card-text mt-3">
        To support healthcare sector with simple digital solutions that can exponentially improve healthcare through advanced monitoring, diagnosis, prediction, treatment and recovery protocols.
        </p>
        </div>
      
        <div class="mt-1 pt-5 learn">
          {/* <div className="text-left">
          <a href="/solution" style={{color:"#4A99D3"}} >Learn More <i class="pl-3 fas fa-arrow-right"></i></a>
          </div> */}
          </div>
      </div>

      </div>
      </div>
      <div>

      </div>
      </div> 
       </>
    );
  }
}