import React, { Component } from "react";
import { MDBContainer, MDBRow, MDBCol, MDBTypography } from "mdbreact";
import Vision from '../../images/svg/vision.svg';
import Mission from '../../images/svg/mission.svg';
 import { ArrowRightOutlined, CaretRightOutlined } from '@ant-design/icons';
 
export default class Cardss extends Component {
  constructor(props) {
    super(props);

    this.state = {
      content: ""
    };
  }

  componentDidMount() {
   
  }

  render() {
    return (
      <>
       <div className="mt-5">
    
        <MDBRow>
        <MDBCol style={{maxWidth:"20%"}}>
        </MDBCol>
        <MDBCol>
          
        <div className="mt-4 pt-4">
        <div className="card1" >
          <div className="card-body text-left">
            <div className="ml-3">
            <img className="img-fluid  ml-5 pl-5" style={{width:"45%"}} src={Vision} />
            </div>
          <div className="text-left">
            <h5 className="card-title mt-1">Vision</h5>
            <p className="card-text mt-3">
            Enable healthcare providers to deliver quality care to patients remotely, anywhere, anytime.          
              </p>
            </div>
           
          <div class="mt-5 pt-5 learn">
          {/* <div className=" text-left">
          <a href="/solution" style={{color:"#4A99D3"}} >Learn More <i class="pl-3 fas fa-arrow-right"></i></a>
          </div> */}
          </div>
          </div>
         
        </div>
        </div>
        </MDBCol>
        <MDBCol >
     
        <div className="text-left mt-5">
       <div className="card1" >
          <div className="card-body text-left">
          <div className="ml-3">
          <img className="img-fluid leftmarginIcon11 ml-5 pl-5" style={{width:"40%" }} src={Mission} />
            </div>
          <div className="text-left">
            <h5 className="card-title mt-5">Mission</h5>
            <p className="card-text mt-3">
            To support healthcare sector with simple digital solutions that can exponentially improve healthcare through advanced monitoring, diagnosis, prediction, treatment and recovery protocols.
            </p>
            </div>
           
          <div class="mt-2 pt-1 pb-4 mb-2 learn">
          {/* <div className="text-left">
          <a href="/solution" style={{color:"#4A99D3"}} >Learn More <i class="pl-3 fas fa-arrow-right"></i></a>
          </div> */}
          </div>
          </div>
         
        </div>
        </div>
        </MDBCol>
        </MDBRow>
        </div>
      </>
    );
  }
}