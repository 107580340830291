import React, { Component,useCallback} from "react";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
 import { MDBNavbar, MDBNavbarBrand, MDBNavbarNav, MDBNavbarToggler, MDBCollapse, MDBNavItem, MDBNavLink, MDBContainer, MDBMask, MDBView } from 'mdbreact';
import logo from '../src/images/logo.png';
import logoSvg from '../src/images/svg/logo.svg';
 import FB from '../src/images/svg/faccebook.svg';
import TW from '../src/images/svg/twitter.svg';
import Menu from '../src/images/svg/menu.svg';
import PT from '../src/images/svg/Instagram.svg';
import WK from '../src/images/svg/linkdin.svg';
 import Contact from "./components/contact";
import About from "./components/about";
import Home from "./components/home";
import Footer from "./components/footer";
import FAQ from "./components/faq";
import Solution from "./components/solutions";
import ScrollToTopRoute from './ScrollToTop';
import './styles.css'
 import Slider1 from "./components/homeSection/sliderSmallScreen";

 
 class App extends Component {
  constructor(props) {
    super(props);
    this.onClick = this.onClick.bind(this);
    this.onClickBtn = this.onClickBtn.bind(this);

    this.state = {
      collapse: false,
      isWideEnough: false    };
  }

  onClickBtn = () =>{
    this.setState({
      collapse: false,
    });
  }
  onClick() {
    this.setState({
        collapse: !this.state.collapse,
      });
  }

  handleClickOutside(event) {
    if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
       this.setState({ collapse: false });
    }
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }


  render() {
    const bg = {backgroundColor:   '#1c2a48'}
    // const container = {height: 1300}
    const { currentUser, showSuperAdminBoard, showAdminBoard,showAdminBoard2,showUserBoard } = this.state;
    let { showOptions } = this.state;
    return (
      <div>
      <Router  >
        <div>
       < >
     
            <MDBNavbar className="navbar textFont navbar-light" style={{backgroundColor:"#ffffff"}} dark expand="md" fixed="top">
            {!this.state.isWideEnough && <MDBNavbarToggler image={Menu} style={{backgroundColor:""}} onClick={this.onClick}/>}

              <MDBNavbarNav href="/home" left>
              <a href="/home" className="hide">
              <img className="img-fluid pl-sm-5 mx-auto " style={{width:"225px",backgroundSize: 'cover'}} src={logo}   alt="mdb logo"/>
              </a>
              <a href="/home" className="hide1">
                 <img className="img-fluid pl-sm-5 mx-auto" style={{width:"230px",backgroundSize: 'cover'}} src={logoSvg}   alt="mdb logo"/>
              </a>

              </MDBNavbarNav>
              <MDBCollapse style={{backgroundColor:""}} isOpen={this.state.collapse} navbar>
              <MDBNavbarNav className="pl-0" center>
              <div className="navFont textToCenter  navbar-collapse " >
            <MDBNavItem onClick={this.onClickBtn} className="pr-4 ">
                       <MDBNavLink  className="" style={{ color:'#000000'}} to={"/home"}>Home</MDBNavLink>
                  </MDBNavItem>
                  <MDBNavItem onClick={this.onClickBtn} className="pr-4">
                      <MDBNavLink style={{color:'#000000'}} to={"/solution"}>Solutions</MDBNavLink>
                  </MDBNavItem>
                  <MDBNavItem onClick={this.onClickBtn} className="pr-4">
                      <MDBNavLink style={{color:'#000000'}} to={"/about"}>About</MDBNavLink>
                  </MDBNavItem>
                
                  <MDBNavItem onClick={this.onClickBtn} className="pr-4">
                      <MDBNavLink style={{color:'#000000'}} to={"/faq"}>FAQ</MDBNavLink>
                  </MDBNavItem>
                  <MDBNavItem onClick={this.onClickBtn} className="pr-4">
                      <MDBNavLink style={{color:'#000000'}} to={"/contact"}>Contact</MDBNavLink>
                  </MDBNavItem>
  
                </div>
            </MDBNavbarNav>
                <MDBNavbarNav className="hide" right>
               <div className="navbar-nav d-flex" >
              
              <li className="nav-item">
              <a  href="https://m.facebook.com/Remocares/">   
              <img className="img-fluid pr-4" style={{width:""}} src={ FB} />
              </a>
              </li>
              <li className="nav-item">
              <a  href="https://twitter.com/remocares?s=11">   
              <img className="img-fluid pr-4" style={{width:""}} src={TW} />
              </a>
              </li>
               <li className="nav-item">
               <a  href="https://www.instagram.com/remocares/?igshid=1gd5ri5ibh0qe">   
              <img className="img-fluid pr-4" style={{width:""}} src={PT} />
              </a>             
               </li>
              <li className="nav-item">
              <a  href="https://www.linkedin.com/company/remocare">   
              <img className="img-fluid pr-4" style={{width:""}} src={WK} />
              </a>  
              </li>
              </div>
      
                </MDBNavbarNav>
                <div></div>
                <MDBNavbarNav className="hide1 ">
               <div className="navbar-nav bottomPad topPad  text-center" style={{display: "inline"}} >
               <a  href="https://m.facebook.com/Remocares/">   
               <img className="img-fluid pr-5" style={{width:"auto"}} src={ FB} />
              </a>
              <a  href="https://twitter.com/remocares?s=11">   
              <img className="img-fluid pr-5" style={{width:"auto"}} src={ TW} />
              </a>
              <a  href="https://www.instagram.com/remocares/?igshid=1gd5ri5ibh0qe">   
              <img className="img-fluid pr-5" style={{width:"auto"}} src={ PT} />
              </a>    
              <a  href="https://www.linkedin.com/company/remocare">   
              <img className="img-fluid " style={{width:"auto"}} src={ WK} />
              </a>  
              </div>
      
                </MDBNavbarNav>
              </MDBCollapse>
            </MDBNavbar>
        
        </>
        </div>
        <div className="header" ref={(node) => this.setWrapperRef = node}>  
       
             <Switch>
            <div className="container1">
              <ScrollToTopRoute exact path={["/", "/home"]} component={Home} />
              <ScrollToTopRoute path="/faq" component={FAQ} />
              <ScrollToTopRoute path="/footer" component={Footer} />
              <ScrollToTopRoute path="/about" component={About} />
              <ScrollToTopRoute path="/solution" component={Solution} />
              <ScrollToTopRoute path="/timeline" component={Slider1} />

              
              </div>
              </Switch>
              <Switch>
              <div  className="container3">
              <Route exact path="/contact" component={Contact} />
              </div>
            </Switch>
         </div>
      <Footer />
      </Router>
              
      </div>
    );
  }
}

export default App;
