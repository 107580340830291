import React, { Component } from "react";
import {Helmet} from "react-helmet";
import { MDBContainer, MDBRow, MDBCol, MDBTypography } from "mdbreact";
import { Collapse, Card } from 'antd';
import { UpOutlined } from '@ant-design/icons';
 import 'antd/dist/antd.css';
var Accordion = require('react-bootstrap').Accordion;
 
const { Panel } = Collapse;
const text = `
  A dog is a type of domesticated animal.
  Known for its loyalty and faithfulness,
  it can be found as a welcome guest in many households across the world.
`;
 
export default class FAQ extends Component {
  constructor(props) {
    super(props);

    this.state = {
      content: ""
    };
  }

  componentDidMount() {
   
  }

  render() {
    return (
      <>
      <Helmet>
      <title>FAQ | Remo.care</title>
        <link rel="canonical" href="https://remo.care/faq" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="article" />
        <meta property="og:title" content="FAQ | Remo.care" />
        <meta property="og:description" content="Frequently Asked Questions We have the answers​ Where is the device available ? The device is currently for In-hospital use for remo partner hospitals. Can an Individual purchase the device? No, we are currently only operating with hospitals, we will have our out-patient monitoring system remo.vitals in the markets by February. Battery life for 1 [&hellip;]" />
        <meta property="og:url" content="https://remo.care/faq/" />
        <meta property="og:site_name" content="Remo.care" />
	      </Helmet>
      <MDBContainer className="mpad1   smallWidth mt-5 pt-5">
        <div  className="center1 pl-sm-5">
          <Card className="faq" style={{ width: 800 }}>
          <div className="card-body1 text-left">
          <div className="text-center">
      <h3 className=" " style={{textDecoration:  'underline'}}>FAQ</h3>
      </div>
      <div className="topPad35">
      <Collapse
        expandIconPosition='right'
       ghost
      //  defaultActiveKey={['1']} 
       bordered={false} 
       expandIcon={({ isActive }) => <UpOutlined style={{fontSize:'17px'}} rotate={isActive ? 180 : 0} />}
       accordion>
    <Panel header="How can I purchase remo.cardia?" key="1">
    <p>If you are a doctor, hospital owner, home care service provider or clinical research organization, please reach out to our sales team at <a href="">hello@remo.care</a> or write us a note by clicking <a href="/contact" style={{textDecoration: 'underline'}}> here</a>.</p>
      <hr/>
      <p>If you are a patient or an individual, please reach out to your nearest clinic/hospital/ doctor and ask about remo.cardia. Otherwise, please drop us a note by clicking <a href="/contact"  style={{textDecoration: 'underline'}}> here</a> and provide us the details of your nearest clinic/hospital/doctor.</p>
    </Panel>
    <Panel header="Can an individual purchase remo.cardia?" key="2">
      <p>Yes, you can either purchase the device or buy a subscription. The system also requires a doctor in loop. So we will let you connect us with your doctor or we will connect you with our panel doctor.</p>
    </Panel>
    <Panel header="Can one subscribe to remo.cardia?" key="3">
      <p>Yes. We have various subscription options available. Please contact us by clicking  <a href="/contact"  style={{textDecoration: 'underline'}}> here</a> and we would be happy to customize subscriptions based on your requirement.
</p>
    </Panel>
    <Panel header="Is the wearable device reusable or one-time-use?" key="4">
      <p>remo.cardia system comes with a reusable, rechargeable wearable patch. For a normal use, the battery of wearable patch lasts for 4-5 days. The patch can be recharged and worn again using a new adhesive.
</p>
    </Panel>
    <Panel header="Can remo.cardia integrate with other ECG devices in my hospital/clinic to enable us to perform remote cardiac monitoring?
" key="5">
      <p>Most likely, yes – if it is a Bluetooth or Wifi enabled device. Drop us a note about your device make and model by clicking <a href="/contact"  style={{textDecoration: 'underline'}}> here</a>. We will review the information and get back to you. Please note that a nominal fee applies for customization of remo.cardia device integrations.
</p>
    </Panel>
    <Panel header="Does Remo Care deal with remote ECG/cardiac monitoring only? Do you support other vitals such as SPO2, Blood Pressure, Glucometer etc." key="6">
      <p>At this point, we are focusing on remote cardiac monitoring. We will keep you posted when additional vitals are supported by us. Please drop your specific requirement by clicking <a href="/contact"  style={{textDecoration: 'underline'}}> here</a>.
</p>
    </Panel>
    
  </Collapse></div>
          </div>
         
        </Card>
      </div>
      </MDBContainer>
      </>
    );
  }
}
