import * as React from "react";
import { render } from "react-dom";
import { ProgressBar,Step } from "react-step-progress-bar";
import { MDBContainer, MDBRow, MDBCol, MDBTypography } from "mdbreact";
import Solutions from '../images/solutions.png';

import "react-step-progress-bar/styles.css";
import AI from '../images/AI.png'
import Cloud from '../images/cloud.png'
import Doctor from '../images/doctor.png'
import Man from '../images/man.png'
import Ipad1 from '../images/dash1.png'
import './homeSection/timeline.css'
import '../App.css'
var loop = 0
function TimeLine() {
  const [counter, setCounter] = React.useState(1);
  const [scroll, setScroll] = React.useState(0);

  
  React.useEffect(() => {
        loopCounter();
      }, [counter]);

    const loopCounter = () => { 
            //  if(counter === 110){
              // setCounter(0)
            // }
         (counter >= 0) && (counter <= 110) && setTimeout(() => setCounter(counter + 1), 50);
      }
    

  return (
    <div>
 
     <div className='setWidth pl-5 d-flex alignCenter justify-content-end text-center'>
    <MDBCol className="pt-5 mt-5" md="3">
      <p className="textBold mb-0 pb-0">Patient</p>
      <p  className="">Patient wears the remo.cardia FDA cleared multi-vitals monitoring patch.</p>
       </MDBCol>
      <div md="2">
       <img className="img-fluid pr-3 pb-3" style={{width:'80%'}} src={Ipad1} />
      </div>
      <MDBCol className="pt-5 mt-5" md="3">
      <p className="textBold text-center mb-0 pb-0">Cloud</p>
      <p>Secure cloud syncs data for analysis and data storage.</p>
       </MDBCol>
      <div md="2 ml-5 pl-5">
        <img className="img-fluid " style={{width:'90%'}} src={AI} />

      </div>
      <MDBCol className="pt-5  mt-5" md="3">
      <p className="textBold pb-0 mb-0 pb-0">Doctor</p>
      <p  className="">Doctor monitors multiple patients. Gets alerts in doctor app in case of an event.</p>
      </MDBCol>
       </div>
        <div className="pl-sm-5 ml-5">
   <MDBContainer>
   <div >
      <ProgressBar
      className="scrollBar"
           filledBackground="linear-gradient(to right, #0f4b9e, #12a84b)"
          percent={counter}
         >
   <Step>
    {({ accomplished, index }) => (
      <div
        className={`indexedStep ${accomplished ? "accomplished" : null}`}
      >
       </div>
    )}
  </Step>
  <Step>
    {({ accomplished, index }) => (
      <div
        className={`indexedStep ${accomplished ? "accomplished" : null}`}
      >
       </div>
    )}
  </Step>
  <Step>
    {({ accomplished, index }) => (
      <div
        className={`indexedStep3 ${accomplished ? "accomplished" : null}`}
      >
       </div>
    )}
  </Step>
  <Step>
    {({ accomplished, index }) => (
      <div
        className={`indexedStep4 ${accomplished ? "accomplished" : null}`}
      >
       </div>
    )}
  </Step>
  <Step>
    {({ accomplished, index }) => (
      <div
        className={`indexedStep5 ${accomplished ? "accomplished" : null}`}
      >
       </div>
    )}
  </Step>
</ProgressBar>
</div>
        </MDBContainer>
        </div>
        <div className='setWidth pl-5 ml-5 d-flex alignCenter justify-content-end text-center'>
        <div md="2">
       <img className="img-fluid" style={{width:'90%'}} src={Man} />
      </div>
      <MDBCol className=" " md="3">
      <p className="textBold pb-0 mb-0 pb-0">App</p>
      <p  className="">Remo.cardia patient app collects vital data from patch and uploads to secure cloud.</p>
       </MDBCol>
      <div md="2">
       <img className="img-fluid" style={{width:'90%'}} src={Cloud} />
      </div>
      <MDBCol className=" " md="3">
      <p className="textBold pb-0 mb-0">AI</p>
      <p  className="">The Cardiac AI algoritm perfroms analysis of ECG & vitals.</p>
       </MDBCol>
      <div md="2 ml-5 pl-5">
        <img className="img-fluid" style={{width:'90%'}} src={Doctor} />
      </div>
       </div>
</div>
  );
}

export default TimeLine;