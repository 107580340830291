import React, { Component } from "react";
import {Helmet} from "react-helmet";
import { MDBContainer, MDBRow, MDBCol, MDBTypography,MDBNavLink } from "mdbreact";
import Landing from '../images/landing1.png';
import { Tabs, Tab, TabPanel, TabList } from 'react-web-tabs';
import 'react-web-tabs/dist/react-web-tabs.css';
import Solutions from '../images/solutions.png';
import iPad from '../images/milestone.png';
import { Splide, SplideSlide } from '@splidejs/react-splide';
import Slide from '../images/slide.png';
import CountUp, { startAnimation }  from 'react-countup';
import TimeLine from './slider'
import TimelineSmall from './homeSection/sliderSmallScreen'
import Benefits from './homeSection/benefits'
import Benefits2 from './homeSection/benefits1'
import Line from '../images/svg/line.svg';

import Slider from "react-slick";
import Testimonials from './homeSection/testimonials'
import "slick-carousel/slick/slick.css"; 
 import '../styles.css' 
    var countUpRef = 0
  console.log(countUpRef)

  
export default class Home extends Component {
  constructor(props) {
    super(props);
    this.onClick = this.onClick.bind(this);
 
    this.state = {
      content: "",
      tab:'one',
     };
  }

 

  onClick(value) {
    console.log(value)
    this.setState({
      tab:value
      });
  }

  componentDidUpdate() {
   }
  
  render() {
 
    return (
      <>
         <Helmet>
                <meta charSet="utf-8" />
                <title>Remocare | Advanced Digital Technology in Medical Devices | Remo.care</title>
              	<meta name="description" content="Remo Care offers advanced digital technology in healthcare coupled with Artificial Intelligence in a range of medical devices. Visit remo.care now" />
                <link rel="canonical" href="https://remo.care/" />
                <meta property="og:locale" content="en_US" />
                <meta property="og:type" content="website" />
                <meta property="og:title" content="Remocare | Advanced Digital Technology in Medical Devices | Remo.care" />
                <meta property="og:description" content="Remo Care offers advanced digital technology in healthcare coupled with Artificial Intelligence in a range of medical devices. Visit remo.care now" />
                <meta property="og:url" content="https://remo.care/" />
                <meta property="og:site_name" content="Remo.care" />  
        </Helmet>
      <MDBRow className="mt-5" fluid></MDBRow>

       <MDBRow fluid>
       <MDBCol sm="1"></MDBCol>
       <MDBCol className="hide1 pl-2 ml-4 mt-5 pt-3" md="6" sm="6"> 
            <img className="img-fluid pt-sm-5 mt-sm-5" style={{width:'auto'}} src={Landing} />
            </MDBCol>
       <MDBCol className="justify-content-between" sm="5">
            <div  className="pt-sm-5  heading d-flex align-items-start flex-column"> 
            <MDBTypography tag='h1' className="text-left mpadper20 mpadper55 mainTittle pt-5 headFont leftPad"  style={{
          // display: "flex",
          // fontSize:'52px',
         color: '#0F4B9E',

          lineHeight:1,
          justifyContent: "left",
          alignItems: "left",
        }} >Transform Patient Monitoring.<br/> <MDBTypography tag='h1'className="mainTittle headFont mb-0 pb-0" style={{color:'#00a79e '}}>Save More Lives.</MDBTypography></MDBTypography>
 
          <p className="textFont leftPad" >
          Remo Care integrates <strong>Remote Cardiac Monitoring with Artificial Intelligence</strong> to enable early alerts on eventualities.  </p>
        <div className="text-left leftPadding10 ">
        <MDBNavLink className="buttonLeft" to={"/solution"}>
        <button type="button"  to={"/"} className="btn text-white btn-sm homebtn" style={{fontSize:'18px' }}> 
        Know More 
        <i style={{fontSize:'20px'}} className="pl-5 fas fa-angle-right"></i>
        </button>
        </MDBNavLink>
        </div>
        </div>
            </MDBCol>
            <MDBCol className="hide"  md="6" sm="6"> 
            <img className="img-fluid pt-sm-5 mt-sm-5" style={{width:'auto'}} src={Landing} />
            </MDBCol>
       </MDBRow>
       <div className="pt-3 mt-sm-5">
       <MDBContainer className="mt-4 pt-4" fluid>
       <MDBTypography tag='h2' className="ml-sm-5 pl-sm-5 text-center headFont">How it Works</MDBTypography>
        <div className="hide">
        <TimeLine/> 
        </div>
        <div style={{width:'100%'}}  className="hide1 ">
        <TimelineSmall/>
        </div>
        </MDBContainer>
       </div>
       <div className="pt-5 benefitsRightMargin topPadding30">
       <MDBContainer className=" mt-sm-5 pt-sm-5 topPad" fluid>
       <MDBRow>
       <MDBCol md="6" sm="6">
       <div className="alignCenter"  style={{marginTop:'-60px'}}>
       {this.state.tab=="one" &&
       <img className="img-fluid text-right" style={{width:'80%'}} src={Solutions} />}
       {this.state.tab=="two" &&
        <img className="img-fluid text-right" style={{width:'100%'}} src={iPad} />}
       </div>
  

       </MDBCol>
        <MDBCol md="6" sm="6">
        <Tabs
        
        defaultTab="one"
        onChange={(tabId) => { this.onClick(tabId) }}
      >
        <TabList onChange={this.state.tab} className="textBold textFont">
          <Tab style={{ borderBottomColor: '#00000', width:'50%'}} className="textBold  "  tabFor="one">Solutions</Tab>
          <Tab style={{ borderBottomColor: '#d0d1d5', width:'50%'}} className="textBold  " tabFor="two">About Us</Tab>
         </TabList>
        <TabPanel className="mt-4" tabId="one">
          <p className=" "><strong>remo.cardia –</strong>  the remote cardiac monitoring system assists the doctor in monitoring patients from anywhere, anytime; using an intuitive Artificial Intelligence algorithm that offers early detection and prediction of key cardiac eventualities.</p>
          <p>This is enabled by an FDA certified wireless device that monitors patient vitals real-time, 24/7.</p>
          {/* <p>
          Treating every patient distinctly the Remo.cardia platform analyzes every aspect of the patient medical history to provide an accurate and detailed report to the doctors and nursing station for the prompt diagnosis of patient health.          </p> */}
          <div class="mt-3">
          <div className="text-left ">
            <a href="/solution" className="textBold " style={{color:"#12A84B"}} >Learn More <i class="pl-3 fas fa-arrow-right"></i></a>
          </div>
          </div>
        </TabPanel>
        <TabPanel className="mt-4" tabId="two">
        <p className=" ">Remo Care brings you the ease and efficacy of advanced digital technology in healthcare coupled with analytical depth of Artificial Intelligence in a range of medical devices that monitors and analyzes patient vitals on real time basis.</p>
          {/* <p>
          To support healthcare sector with simple digital solutions that can exponentially improve healthcare through advanced monitoring, diagnosis, prediction, treatment and recovery protocols.
          </p> */}
          <div class="mt-3">
          <div className="text-left  ">
          <a href="/about" className="textBold  " style={{color:"#12A84B"}} >Learn More <i class="pl-3 fas fa-arrow-right"></i></a>
          </div>
          </div>       
        </TabPanel> 
      </Tabs>
        </MDBCol>
        
       </MDBRow>
      
       </MDBContainer>
      </div>
      <div className="mt-5 pt-5 alignCenter1">
      <div className="ml-sm-5 pl-sm-5">
        <MDBTypography tag='h2' className="text-center headFont">Benefits</MDBTypography>
        {/* <p  className="text-center"> Various benefits provided by Remo Care.</p> */}
        </div>

      </div>
      <div style={{width: 'auto'}}  className="benefitsRightMargin hide  alignCenter1">
         <Benefits />
          </div>
          <div className="hide1 benefitPadding">
          <Benefits2 />
          </div>

          {/* <MDBContainer className="mt-5 pt-5 " fluid>
          <div className=" alignCenter1">
          <div className="ml-sm-5 pl-sm-5">
        <MDBTypography tag='h2' className="text-center headFont">What Says Client</MDBTypography>
        <p  className="text-center"> Lorem ipsum dolor sit amet, consectetur adipiscing elit. <br/>Egestas ultrices condimentum magna euismod. </p>
        </div>
        </div>
        <MDBContainer className="alignCenter1">
        <Testimonials/>
        </MDBContainer> 
          </MDBContainer>  */}
          <div className="mt-5 pt-5 alignCenter1">
          <div className="ml-sm-5 pl-sm-5">
        <MDBTypography tag='h2' className="text-center headFont">Our Growing Metric</MDBTypography>
        {/* <p className="text-center"> Lorem ipsum dolor sit amet, consectetur adipiscing elit. Dui netus amet, montes, magna pellentesque quis. </p> */}
        </div>

      </div>
      <div className=" pl-sm-5 ml-sm-5">
      <MDBContainer>
       <div  style={{backgroundImage:`url(${Line})`, repeat:'no-repeat'}} className="pt-sm-5 sectionBgSmall sectionBg pl-5  heading align-items-between "> 
      <MDBRow className=" ">
         <MDBCol>
        <div className="card4  hoverEffectOnText " style={{ height: "12rem",  width: "13rem", borderRadius:"20px" }}>
           <div className="card-body hoverEffectOnText   text-center">
              <h1 className="card-title textBold">20+</h1>      
              <h6 className="sub card-title textBold">Hospitals</h6>           
          <div class="mt-5 pt-5">
          </div>
          </div>
       </div>
        </MDBCol>
 
        <MDBCol className="topPad35 metric">
        <div className="card4 hoverEffectOnText " style={{ height: "12rem",  width: "13rem", borderRadius:"20px" }}>
           <div className="card-body  hoverEffectOnText  text-center">
              <h1 className="card-title textBold">6000+</h1>      
              <h6 className="sub card-title textBold">Hours of monitoring</h6>           
          <div class="mt-5 pt-5">
           </div>
          </div>
       </div>
        </MDBCol>
        <MDBCol className="topPad35 metric">
        <div className="card4  hoverEffectOnText " style={{ height: "12rem",  width: "13rem", borderRadius:"20px" }}>
           <div className="card-body  hoverEffectOnText  text-center">
              <h1 className="card-title textBold">99.7%</h1>      
              <h6  className="sub card-title textBold">Satisfaction ratings</h6>           
          <div class="mt-5 pt-5">
          </div>
          </div>
       </div>
        </MDBCol>
        
       </MDBRow>

      </div>
   
      </MDBContainer>
      </div>
      </>
    );
  }
}
