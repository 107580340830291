import React, { Component } from "react";
import {Helmet} from "react-helmet";
import { MDBContainer, MDBRow, MDBCol, MDBTypography, MDBCard, MDBCardBody, MDBNavLink } from "mdbreact";
 import { List } from 'antd';
import Solutions from '../images/solutions1.png';
 import { Tabs, Tab, TabPanel, TabList } from 'react-web-tabs';
import 'react-web-tabs/dist/react-web-tabs.css';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css"
import ReactPlayer from 'react-player'
import FB from '../images/svg/12.svg';
import YU from '../images/svg/13.svg';
 import AD from '../images/svg/fda.svg';
import CA from '../images/svg/feather.svg';
import RE from '../images/recycle.png';
import LI from '../images/live.jpg';
import { CheckCircleOutlined, CaretRightOutlined } from '@ant-design/icons';

export default class Solution extends Component {
  constructor(props) {
    super(props);
    this.onClick = this.onClick.bind(this);

    this.state = {
      content: "",
      tab:'one',
      url:''
    };
  }

  componentDidMount() {
    
  }
  
  onClick(value) {
    console.log(value)
    this.setState({
      tab:value
      });
  }


  render() {
    const settings = {
      className: "center1",
      centerMode: true,
      infinite: true,
      centerPadding: "60px",
      slidesToShow: 5,
      speed: 500
    };
    return (
      <>
      <Helmet>
            <title>Remo.cardia - Intuitive AI for Doctors to monitor patients | Remo.care</title>
            <meta name="description" content="Remo.cardia assists doctors in remotely monitoring their patients vital stats using a CE approved intuitive Artificial Intelligence algorithm | Remo.care" />
            <link rel="canonical" href="https://remo.care/solution" />
            <meta property="og:locale" content="en_US" />
            <meta property="og:type" content="article" />
            <meta property="og:title" content="Remo.cardia - Intuitive AI for Doctors to monitor patients | Remo.care" />
            <meta property="og:description" content="Remo.cardia assists doctors in remotely monitoring their patients vital stats using a CE approved intuitive Artificial Intelligence algorithm | Remo.care" />
            <meta property="og:url" content="https://remo.care/solution" />
            <meta property="og:site_name" content="Remo.care" />
        </Helmet>

      <div className="mt-5 pt-3">

      </div>
      <MDBRow className="mPad" fluid>
       <MDBCol sm="1"></MDBCol>
       <MDBCol className=" "  md="6" sm="6"> 
            <img className="leftPad img-fluid pt-sm-5 mt-sm-5 imageSol"  src={Solutions} />
            </MDBCol>
       <MDBCol className="justify-content-between" sm="5">
            <div  className="pt-sm-5  heading d-flex align-items-start flex-column"> 
            <MDBTypography tag='h1' className="text-left mpadper30 mpadper55 mainTittle pt-5 headFont leftPad"  style={{
          // display: "flex",
          // fontSize:'52px',
          lineHeight:1,
          justifyContent: "left",
          alignItems: "left",
        }} >The Product</MDBTypography>
 
          <p className="textFont leftPad" >
          remo.cardia analyzes patient ECG and symptoms in real-time for early alerts. The system collects key parameters of patient medical history to provide an accurate and detailed report to the doctors and nursing station for the prompt diagnosis of patient health.
          </p>
        <div className="text-left leftPadding10 ">
        <MDBNavLink className="buttonLeft55   " to={"/contact"}>
        <button type="button" class="btn text-white btn-sm homebtn" style={{fontSize:'18px' }}> 
              Contact Us
        <i style={{fontSize:'20px'}} className="pl-5 fas fa-angle-right"></i>
        </button>
        </MDBNavLink>
        </div>
        </div>
            </MDBCol>
           
       </MDBRow>
        
 <div className="mt-sm-4 pt-sm-3">

</div>
   
       <div className="mt-sm-5 pt-sm-4 alignCenter1">
      <div className="mt-2  pt-2">
        <MDBTypography tag='h2' className="leftPad ml-sm-5 text-center headFont">Remote Monitoring As A Service</MDBTypography>
        {/* <p  className="text-center"> Lorem ipsum dolor sit amet, consectetur adipiscing elit.  <br/>
        Sodales at at facilisis a tristique. Et ultrices.s</p> */}
        </div>

      </div>
      <MDBContainer className="benefitsRightMargin1 pt-5" fluid>
       <MDBRow>
       <MDBCol md="5" sm="12">
       <div className="mpadper1" style={{marginTop:'-80px'}}>
       {/* {this.state.tab=="one" && */}
       <div className="leftPad5 center">
            <div className="player-wrapper">
                <ReactPlayer
                  url="https://vimeo.com/523118903"
                  className="react-player"
                  width="92%"
                   height="100%"
                  controls={true}
                  // playing
                />
              </div>
            </div>
       {/* {this.state.tab=="two" &&
       <img className="img-fluid text-right" style={{width:'100%'}} src={tab1} />}
       {this.state.tab=="three" &&
       <img className="img-fluid text-right" style={{width:'100%'}} src={tab3} />} */}
       </div>
  

       </MDBCol>
        <MDBCol md="7" sm="12">
        <Tabs
        defaultTab="one"
        onChange={(tabId) => { this.onClick(tabId) }}
      >
        <TabList onChange={this.state.tab} className="textBold textFont hide1">
          <Tab style={{ borderBottomColor: '#00000', fontSize:'16px',width:'57%' }} className="textBold pl-0 pr-0"  tabFor="one">Remote Cardiac <br/> Monitoring</Tab>
          <Tab style={{ borderBottomColor: '#d0d1d5', fontSize:'16px',width:'43%' }} className="textBold pl-0 pr-0" tabFor="two">Live Analysis <br/>And Alert</Tab>
          {/* <Tab style={{ borderBottomColor: '#d0d1d5'}} className="textBold" tabFor="three">Live Feedback</Tab> */}
         </TabList>
         <TabList onChange={this.state.tab} className="textBold textFont hide">
          <Tab style={{ fontSize:'20px', borderBottomColor: '#00000',width:'50%' }} className="textBold pl-0 pr-0"  tabFor="one">Remote Cardiac Monitoring</Tab>
          <Tab style={{ fontSize:'20px', borderBottomColor: '#00000',width:'50%' }} className="textBold pl-0 pr-0" tabFor="two">Live Analysis And Alert</Tab>
          {/* <Tab style={{ borderBottomColor: '#d0d1d5'}} className="textBold" tabFor="three">Live Feedback</Tab> */}
         </TabList>
        <TabPanel className="mt-4" tabId="one">
          <p className=" ">The ability to monitor a patient's heart health such as arrhythmias, rhythm issues, beat issues etc. have proven to be advantageous in early and conclusive diagnosis. Fewer in-person visits are necessary, saving patients effort, time and money.</p>
          <div className="d-flex pb-2">
           <CheckCircleOutlined className="pr-1" style={{fontSize:25,color:'#12A84B'}}/>
           <p className="pb-0 mb-0 text-left">Monitor patients 24×7.</p>
           </div>
           <div className="d-flex pb-2">
           <CheckCircleOutlined className="pr-1" style={{fontSize:25,color:'#12A84B'}}/>
           <p className="pb-0 mb-0 text-left">Get accurate updates remotely.</p>
           </div>
          
           <div class="mt-2">
          <div className="text-left downloadbtn">
        <MDBNavLink className=" " to={`${process.env.PUBLIC_URL}/remo.cardia-Brochure.pdf`} target="_blank" download>
        <button type="button"  className="btn pl-5 text-white btn-sm homebtn" style={{fontSize:'18px' }}> 
              Brochure
        <i style={{fontSize:'20px'}} className="pl-5 ml-2 fa fa-download"></i>
        </button>
        </MDBNavLink>
        </div>
          </div>  
        </TabPanel>
        <TabPanel className="mt-4" tabId="two">
        <p className=" ">Traditional cardiac event recorders are bulky and mostly perform offline analysis. remo.cardia’s AI algorithm can perform in-stream noise filtering, real-time analysis and alert on an eventuality. All this, while the patient is doing his/her day-to-day activities without interruption, thanks to the lightweight, non-activity limiting wearable device.</p>
          {/* <p>
          These components have come together in cardiac monitoring devices to enable real-time, 24/7 monitoring on all data. The future standard of care will likely be online, real-time monitoring combined with smart algorithms or artificial intelligence.          </p> */}
          <div className="d-flex pb-2">
           <CheckCircleOutlined className="pr-1" style={{fontSize:25,color:'#12A84B'}}/>
           <p className="pb-0 mb-0 text-left">Analyze ECG and Vitals real-time and get alerts on events.</p>
           </div>
           <div className="d-flex pb-2">
           <CheckCircleOutlined className="pr-1" style={{fontSize:25,color:'#12A84B'}}/>
           <p className="pb-0 mb-0 text-left">Get live symptom logs and drug feedback.</p>
           </div>
          
          <div class="mt-2">
          <div className="text-left downloadbtn">
          <MDBNavLink className=" " to={`${process.env.PUBLIC_URL}/remo.cardia-Brochure.pdf`} target="_blank" download>
        <button type="button"    className="btn pl-5 text-white btn-sm homebtn" style={{fontSize:'18px' }}> 
              Brochure
        <i style={{fontSize:'20px'}} className="pl-5 ml-2 fa fa-download"></i>
        </button>
        </MDBNavLink>
        </div>
          </div>       
        </TabPanel> 
        {/* <TabPanel className="mt-4" tabId="three">
          <p className="textBold">Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua.</p>
          <p>
          Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasdLorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut
          </p>
          <div class="mt-3">
          <div className="text-left">
            <a href='/contact' className="textBold" style={{color:"#12A84B"}} >Learn More<i class="pl-3 fas fa-arrow-right"></i></a>
          </div>
          </div>
        </TabPanel> */}
      </Tabs>
        </MDBCol>
        
       </MDBRow>
      
       </MDBContainer>
       <div className="mt-5 pl-3 alignCenter1">
      <div className="mt-2 leftPad pt-2">
        <MDBTypography tag='h2' className="text-center headFont">Solution Features</MDBTypography>
        {/* <p className="text-center"> Lorem ipsum dolor sit amet, consectetur adipiscing elit. Dui netus amet, montes, magna pellentesque quis. </p> */}
        </div>

      </div>
      <MDBContainer>
       <MDBRow className=" ">
         <MDBCol md="2" sm="6"></MDBCol>
        <MDBCol  md="5" sm="6" className="">
        <List
                split={false}
            itemLayout="horizontal">
               <List.Item>
               <img style={{width:'15%'}}   src={AD} />
                <List.Item.Meta
                  title={<h5 className="pl-3" style={{fontWeight:'normal',paddingTop:'2%'}}>FDA and CE cleared device​</h5>}
                   />
                   </List.Item>
                   <List.Item>
                   <img style={{width:'15%'}}   src={YU} />
                <List.Item.Meta
                   title={<h5 className="pl-3" style={{fontWeight:'normal',paddingTop:'2%'}}>1 lead, 3 channel ECG​</h5>}
                   />
                   </List.Item>
                   <List.Item>
                   <img style={{width:'14%'}}   src={CA} />
                <List.Item.Meta
                   title={<h5 className="pl-3" style={{fontWeight:'normal',paddingTop:'2%'}}>Ultra light weight – 7.5gms​</h5>}
                   />
                   </List.Item>
               
                   </List>
        </MDBCol>
        <MDBCol  md="5" sm="6" className=" ">
        <List
        className="pt-sm-2"
        split={false}
            itemLayout="horizontal">
            <List.Item>
                   <img style={{width:'12%'}}   src={FB} />
                <List.Item.Meta
                   title={<h5 className="pl-3" style={{fontWeight:'normal',paddingTop:'2%'}}>IP25 Water resistant ​</h5>}
                   />
                   </List.Item>
                
                   <List.Item>
                   <img style={{width:'12%'}}   src={RE} />
                <List.Item.Meta
                   title={<h5 className="pl-3" style={{fontWeight:'normal',paddingTop:'2%'}}>Reusable & Rechargeable</h5>}
                   />
                   </List.Item>
                   <List.Item>
                   <img style={{width:'12%'}}   src={LI} />
                <List.Item.Meta
                   title={<h5 className="pl-3" style={{fontWeight:'normal',paddingTop:'2%'}}>Live stream and recording</h5>}
                   />
                   </List.Item>
                   </List>
        </MDBCol>
        <MDBCol  md="1" sm="6"></MDBCol>
       </MDBRow>

    
      </MDBContainer>
       
     
       
      </>
    );
  }
}
