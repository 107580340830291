import * as React from "react";
import { render } from "react-dom";
import { ProgressBar,Step } from "react-step-progress-bar";
import { MDBContainer, MDBRow, MDBCol, MDBTypography } from "mdbreact";

import "react-step-progress-bar/styles.css";
 
import './timeline.css'
import '../../App.css'
var loop = 0
function TimeLineSmall() {
  const [counter, setCounter] = React.useState(1);
  const [scroll, setScroll] = React.useState(0);

  
  React.useEffect(() => {
      //  counter > 0 && counter < 100 && setTimeout(() => setCounter(counter + 1), 50);
       loopCounter();
      }, [counter]);

    const loopCounter = () => { 
             if(counter === 110){
              setCounter(0)
            }
         (counter >= 0) && (counter <= 110) && setTimeout(() => setCounter(counter + 1), 50);
       }
    

  return (
 
      <ProgressBar
      className="scrollBar"
           filledBackground="linear-gradient(to right, #0f4b9e, #12a84b)"
          percent={counter}
         >
   <Step>
    {({ accomplished, index }) => (
      <div
        className={`indexedStep ${accomplished ? "accomplished" : null}`}
      >
       </div>
    )}
  </Step>
  <Step>
    {({ accomplished, index }) => (
      <div
        className={`indexedStep ${accomplished ? "accomplished" : null}`}
      >
       </div>
    )}
  </Step>
  <Step>
    {({ accomplished, index }) => (
      <div
        className={`indexedStep3 ${accomplished ? "accomplished" : null}`}
      >
       </div>
    )}
  </Step>
  <Step>
    {({ accomplished, index }) => (
      <div
        className={`indexedStep4 ${accomplished ? "accomplished" : null}`}
      >
       </div>
    )}
  </Step>
  <Step>
    {({ accomplished, index }) => (
      <div
        className={`indexedStep5 ${accomplished ? "accomplished" : null}`}
      >
       </div>
    )}
  </Step>
</ProgressBar>
 
  );
}

export default TimeLineSmall;