import React, { Component } from "react";
import { MDBContainer, MDBRow, MDBCol, MDBTypography } from "mdbreact";
import Slide from '../../images/slide.png';
import Client from '../../images/client.png';

import Slider from "react-slick";
import { CaretLeftOutlined, CaretRightOutlined } from '@ant-design/icons';

 import "slick-carousel/slick/slick.css"; 
 import '../../styles.css' 
 

function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <CaretRightOutlined
      className={className}
      style={{ ...style, display: " "}}
      onClick={onClick}
    />
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: " "}}
      onClick={onClick}
    />
  );
}

export default class Testimonials extends Component {
  constructor(props) {
    super(props);

    this.state = {
      content: ""
    };
  }

  componentDidMount() {
   
  }

  render() {
    var settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
            className: "center",
          centerPadding: "160px",
   
         responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              infinite: true,
              dots: true
            }
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              initialSlide: 1
            }
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1
            }
          }
        ]
      };
    return (
      <>
         <Slider style={{width:'100%'}} {...settings}>
          <div>
          <div className="d-sm-flex justify-content-center">
        <MDBCol md="2" sm="2">
        <div className="text-center center1 paddingTopSet" >
        <img style={{width:'150px', paddingTop:'0px'}} src={Client}/>

        </div>
        </MDBCol>
        <MDBCol md="5" sm="6">
          <div><p className="headFont mb-2  mt-5" style={{marginBottom:'0em',fontSize:"100px",fontStyle:"italic",lineHeight:"1px"}}>"</p></div>
        <p  className="textFont text-center"> 
         Lorem ipsum dolor sit amet, consectetur adipiscing elit.
        Lorem ipsum dolor sit amet, consectetur adipiscing elit.Egestas ultrices condimentum magna euismod. "</p>
        <div><p className="headFont text-right mt-5" style={{fontSize:"100px",fontStyle:"italic",lineHeight:"1px"}}>"</p></div>

        </MDBCol>
        </div>
          </div>
          <div>
          <div className="d-sm-flex justify-content-center">
        <MDBCol md="2" sm="2">
        <div className="text-center center1 paddingTopSet" >
        <img style={{width:'150px', paddingTop:'0px'}} src={Client}/>

        </div>
        </MDBCol>
        <MDBCol md="5" sm="6">
          <div><p className="headFont mb-2  mt-5" style={{marginBottom:'0em',fontSize:"100px",fontStyle:"italic",lineHeight:"1px"}}>"</p></div>
        <p  className="textFont text-center"> 
         Lorem ipsum dolor sit amet, consectetur adipiscing elit.
        Lorem ipsum dolor sit amet, consectetur adipiscing elit.Egestas ultrices condimentum magna euismod. "</p>
        <div><p className="headFont text-right mt-5" style={{fontSize:"100px",fontStyle:"italic",lineHeight:"1px"}}>"</p></div>

        </MDBCol>
        </div>
       </div>
          <div>
          <div className="d-sm-flex justify-content-center">
        <MDBCol md="2" sm="2">
        <div className="text-center center1 paddingTopSet" >
        <img style={{width:'150px', paddingTop:'0px'}} src={Client}/>

        </div>
        </MDBCol>
        <MDBCol md="5" sm="6">
          <div><p className="headFont mb-2  mt-5" style={{marginBottom:'0em',fontSize:"100px",fontStyle:"italic",lineHeight:"1px"}}>"</p></div>
        <p  className="textFont text-center"> 
         Lorem ipsum dolor sit amet, consectetur adipiscing elit.
        Lorem ipsum dolor sit amet, consectetur adipiscing elit.Egestas ultrices condimentum magna euismod. "</p>
        <div><p className="headFont text-right mt-5" style={{fontSize:"100px",fontStyle:"italic",lineHeight:"1px"}}>"</p></div>

        </MDBCol>
        </div>
          </div>
         </Slider>
      
      </>
    );
  }
}