import React, { Component } from "react";
import {Helmet} from "react-helmet";
import { MDBContainer, MDBRow,MDBCol, MDBTypography,MDBBtn  } from 'mdbreact';
import {
  Form,
    FormInput ,
    FormTextarea, 
    InputGroup,
    CardFooter,
    Button
  } from "shards-react";
import FB from '../images/svg/faccebook.svg';
import YU from '../images/svg/Youtube.svg';
import IN from '../images/svg/Instagram.svg';
import AD from '../images/svg/address.svg';
import CA from '../images/svg/call.svg';
import MA from '../images/svg/mail.svg';
import TW from '../images/svg/twitter.svg';
import PT from '../images/svg/pintrest.svg';
import WK from '../images/svg/wk.svg';
import { List, Avatar,message } from 'antd';
import validator from 'validator'

import emailjs from 'emailjs-com';
const required = value => {
  if (!value) {
    return (
      <div className="alert alert-danger" role="alert">
        This field is required!
      </div>
    );
  }
};

export default class Contact extends Component {
  constructor(props) {
    super(props);
    this.handleMessage = this.handleMessage.bind(this);
    this.onChangeUsername = this.onChangeUsername.bind(this);
    this.onChangeMessage = this.onChangeMessage.bind(this);
    this.onChangeEmail = this.onChangeEmail.bind(this);
    this.onChangeNumber = this.onChangeNumber.bind(this);

    
    this.state = {
      username: "",
      email:"",
      messages: "",
      error:"",
      number:'',
      notValid:''
    };
  }

  onChangeMessage(e) {
    this.setState({
      messages: e.target.value
    });
  }
  onChangeUsername(e) {
    this.setState({
      username: e.target.value
    });
  }
  
  onChangeEmail(e) {
    var emailId =  e.target.value
    this.setState({
      email:emailId
    });
    if (validator.isEmail(emailId)) {
        
      this.setState({
        notValid: ""
      });
    } else {
      this.setState({
        notValid: '*Enter valid Email.'
      });
     }
  }

  onChangeNumber(e) {
    this.setState({
      number: e.target.value
    });

  }

  handleMessage(e) {
    e.preventDefault();
    if(this.state.username === "" ||this.state.email === ""|| this.state.messages === ""||this.state.number === ""){
      this.setState({
        error:"*All fields are required."
      })
    }
    else{
    const { username, email, messages, number} = this.state
    let templateParams = {
      from_name: 'Remo Care',
      to_name: `${username}`,
      subject: 'Message',
      message: messages,
      email:email,
      number: number
     }
     emailjs.send(
      'service_otleyib',
      'template_zwe258l',
       templateParams,
      'user_l8u0Ud4sjZ6UmlchcDqpV',
     ).then(res => {
      this.setState({
        error:""
      })
      message.success({
        content: 'Message Sent',
        className: 'custom-class',
        style: {
          marginTop: '10vh',
        },
      });
      console.log(res,"sent")
     
    })
    // Email Failed to send Error alert
    .catch(err => {
      console.log(err,"Failed")
      message.error({
        content: 'Failed to Sent',
        className: 'custom-class',
        style: {
          marginTop: '10vh',
        },
      });
      
    })
     this.resetForm()
  }
 }
resetForm() {
    this.setState({
      username: '',
      email: '',
      subject: '',
      messages: '',
      number:''
    })
  }

  render() {
    return (
      <>
      <Helmet>
       <title>Contact Us | Remo.care</title>
        <link rel="canonical" href="https://remo.care/contact" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="article" />
        <meta property="og:title" content="Contact Us | Remo.care" />
        <meta property="og:description" content="Contact Us ​ Address​ 3rd Floor Vallamattam Estate, M.G Road, Ravipuram, Ernakulam, Kerala – 682015 , India Email hello@remo.care Call +91 9846570700 | 04844028700 Website https://remo.care/ Talk to us&#8230;" />
        <meta property="og:url" content="https://remo.care/contact-us/" />
        <meta property="og:site_name" content="Remo.care" />
      </Helmet>
      <div className="container4 topPad35 cardWidth">
      <div className="pt-5" >
             <div className=" leftMargin211 pl-sm-5 mt-sm-5">
            <MDBCol style={{ maxWidth: " " }}>
            <div style={{backgroundColor:'#ffffff'}} className="faq contactForm  mt-3 ">
            <div className="card-body" fluid>
            <MDBRow className="">
            <MDBCol sm="12">
                    <MDBTypography tag='h3' className="text-center mt-3 headFont" style={{fontWeight:' ', textDecorationLine: 'underline'}}>Get In Touch</MDBTypography>
                    </MDBCol>
            </MDBRow>
            <MDBRow >
            {/* <MDBCol sm="1"></MDBCol> */}
            <MDBCol className="mb-4
             pl-sm-5 ml-sm-3" sm="6">
             <div className="textToCenter topPad10 text-center">
             <MDBTypography tag='h6' className="text-sm-left textToCenter textFont" style={{fontWeight:''}}>Leave us a message</MDBTypography>
             </div>
             <Form>
             <InputGroup className="mb-3">
             <FormInput
                      required
                         type="text"
                          className="form-control mt-3"
                          value={this.state.username}
                          onChange={this.onChangeUsername}
                      placeholder="Full Name" />
            </InputGroup>
                
                    <FormInput 
                    placeholder="Email Address"
                      type="text"
                      required
                          className="form-control mt-3"
                          value={this.state.email}
                          onChange={this.onChangeEmail}/>
                            {this.state.notValid && 
                   <div className="text-danger">
                     <p className="mb-0" style={{fontSize:13}}>{this.state.notValid}</p>
                   </div>}
                   <FormInput 
                    placeholder="Phone No."
                       type="number" pattern="[0-9]*" inputmode="numeric"
                      required
                          className="form-control mt-3"
                          value={this.state.number}
                          onChange={this.onChangeNumber}/>
                    <FormTextarea
                          type="text"
                          required
                          className="form-control mt-3"
                          value={this.state.messages}
                          onChange={this.onChangeMessage}
                          rows={5} placeholder="Your Message" />
                   {this.state.error && 
                   <div className="text-danger">
                     <p className="mb-0" style={{fontSize:13}}>{this.state.error}</p>
                   </div>}
                      <div className="text-center pt-3 leftPad10">
                  {/* <Link to={{pathname: "/contact", }}/> */}
                  <Button type="button"     onClick={this.handleMessage}  href="/contact" className="btn text-white btn-sm homebtn" style={{fontSize:'18px' }}> 
                      SEND MESSAGE
                  <i style={{fontSize:'20px', marginLeft:'35px'}} className="fas pl-4 fa-angle-right"></i>
                  </Button>
                  </div>
                      </Form>
           </MDBCol> 
            <MDBCol className="pl-sm-5 mt-sm-3" sm="5">
                <List
            itemLayout="horizontal">
               <List.Item>
                <List.Item.Meta
                  avatar={<img src={AD} />}
                  title={<p >3rd Floor Vallamattam Estate, M.G Road, Ravipuram, Ernakulam, Kerala – 682015 , India.</p>}
                   />
                   </List.Item>
                   <List.Item>
                <List.Item.Meta
                  avatar={<img src={CA} />}
                  title={<p>+91 9995514483</p>}
                   />
                   </List.Item>
                   <List.Item>
                <List.Item.Meta
                  avatar={<img src={MA} />}
                  title={<p>hello@remo.care</p>}
                   />
                   </List.Item>
                   </List>
                   <div>
                   <a  href="https://www.youtube.com/channel/UCmmjaHvPfaHSkPvFExraHqQ"><img src={YU} className=""/></a>
                   <a  href="https://www.instagram.com/remocares/?igshid=1gd5ri5ibh0qe"> <img src={IN} className="pl-3"/></a>
                   <a  href="https://m.facebook.com/Remocares/"><img src={FB} className="pl-3"  style={{width:40,height:20}} /></a>
                   <a  href="https://twitter.com/remocares?s=11"><img src={TW} className="pl-3"/></a>
                   </div>
                   <div className="mt-3 google-map-code">
                     <iframe  width="98%" height="150" frameborder="0" scrolling="no" marginheight="0" marginwidth="0" src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=3rd%20Floor%20Vallamattam%20Estate,%20M.G%20Road,%20Ravipuram,%20Ernakulam,%20Kerala%20%E2%80%93%20682015%20,%20India+(My%20Business%20Name)&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"></iframe> 
                  </div>
             </MDBCol>
            </MDBRow>     
                   
            </div>
            </div>
    </MDBCol>
 
    </div>
       </div>
      </div>
      </>
    );
  }
}
